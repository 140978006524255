import * as React from "react";
import axios from "axios";
import { graphql } from "gatsby";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { api } from "../../config";
import Form from "../../components/form/form";
import { Input } from "../../components/form/form";
import { Column } from "../../components/primitives";
import { getAccessToken, isLoggedIn } from "../../lib/auth";
import Layout from "../../components/layout/layout";
import { navigateTo } from "../../lib/UrlUtils";
import "./change-password.scss";

export const query = graphql`
  query ($languageISO: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
  }
`;

const ChangePassword = ({ data: { ribbon, header, footer } }) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const formRef = React.createRef();

  const onSubmit = ({ newPassword, oldPassword, confirmPassword }) => {
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
    }

    if (!oldPassword) {
      setError("Current password is required");
    }
    const formData = { oldPassword, newPassword };
    return;
    /*axios({
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken}`,
      },
      url: `${api.base}${api.changePassword}`,
      data: formData,
    })
      .then((res) => {
        if (res.data.error) throw new Error(res.data.error);

        this.setState({
          error: !res.data.result ? "Password update failed" : null,
          success: res.data.result ? "Password updated!" : null,
        });
      })
      .catch((err) => {
        this.setState({ error: "Oops something went wrong..." });
      });*/
  };

  const handleShowPassword = ({ target: { name, type, checked, value } }) => {
    // this.setState((state) => ({ ...state, [name]: checked }));
  };

  const submitForm = (e) => {
    formRef.current.onSubmit(e);
  };

  const change = "changePassword";
  if (!isLoggedIn()) navigateTo("/login");

  return (
    <Layout
      header={header}
      ribbon={ribbon}
      footer={footer}
      hasBanner="no-banner"
    >
      <Column height="60vh">
        <h1
          css={css`
            font-size: 24px;
          `}
        >
          {change.change}
        </h1>
        <div
          css={css`
            height: 20px;
            margin: 10px;
          `}
        >
          {error && (
            <span
              css={css`
                color: red;
              `}
            >
              {error}
            </span>
          )}
          {success && (
            <span
              css={css`
                color: green;
              `}
            >
              {success}
            </span>
          )}
        </div>
        <Form
          className="change-password__form"
          onSubmit={onSubmit}
          ref={formRef}
        >
          <Input
            type={showPassword ? "text" : "password"}
            name="oldPassword"
            placeholder="Current Password"
            autoComplete="current-password"
          />
          <Input
            type={showPassword ? "text" : "password"}
            name="newPassword"
            placeholder="New Password"
            autoComplete="newt-password"
          />
          <Input
            type={showPassword ? "text" : "password"}
            name="confirmPassword"
            placeholder="Confirm Password"
            autoComplete="new-password"
          />
        </Form>
        <div className="change-password__form-controls">
          <button
            onClick={submitForm}
            css={css`
              background: #066e66;
              border-color: transparent;
              color: #fff;
              border-radius: 0;
              margin-left: 5px;
              text-transform: uppercase;
              font-family: league-gothic, sans-serif;
              letter-spacing: 1px;
              font-size: 18px;
            `}
          >
            {change.submit}
          </button>
          <form>
            <label
              htmlFor="checkbox"
              className="change-password__form-checkbox-label"
            >
              {change.show}
            </label>
            <input
              id="checkbox"
              type="checkbox"
              name="showPassword"
              onChange={handleShowPassword}
            />
          </form>
        </div>
      </Column>
    </Layout>
  );
};

export default ChangePassword;
